import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../../core/data/redux/action";
import SignUp from "../../components/Auth/Signup";
import Login from "../../components/Auth/Login";
import ForgotPassword from "../../components/Auth/ForgotPassword";
import ResetPassword from "../../components/Auth/ResetPassword";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Dropdown } from "react-bootstrap";
import { BrowserView } from "react-device-detect";
import { benefits } from "../../components/Auth/utils";
import { useTranslation } from "react-i18next";

const lngs = [
  { code: "en", native: "English" },
  { code: "ro", native: "Romanian" }
];

const Header = () => {
  const { t, i18n } = useTranslation();
  const [popUpElement, setPopUpElement] = React.useState<string | undefined>(
    undefined
  );

  const location = useLocation();

  const dispatch = useDispatch();

  const mobileSidebar = useSelector((state: any) => state.mobileSidebar);

  useEffect(() => {
    // get query param
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("popup");
    if (
      ["login", "signup", "resetpassword", "forgotpassword"].includes(
        myParam || ""
      )
    ) {
      setPopUpElement(myParam || undefined);
    }
  }, []);

  // useefect location change
  useEffect(() => {
    // get query param
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("popup");
    if (
      ["login", "signup", "resetpassword", "forgotpassword"].includes(
        myParam || ""
      )
    ) {
      setPopUpElement(myParam || undefined);
    }
  }, [
    location,
    window.location.search,
    window.location.pathname,
    window.location.href
  ]);

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };

  const handleLng = (code: string) => {
    i18n.changeLanguage(code);
    localStorage.setItem("i18nextLng", code);

    if (code !== "ro") {
      // replacestate url with new language
      const url = new URL(window.location.href);
      url.searchParams.set("lang", code);

      window.history.replaceState({}, "", url.toString());
    } else {
      // replacestate url with new language
      const url = new URL(window.location.href);
      url.searchParams.delete("lang");

      window.history.replaceState({}, "", url.toString());
    }
  };
  return (
    <>
      {popUpElement === "signup" && (
        <SignUp
          toggle={() => {
            setPopUpElement(undefined);
            const url = new URL(window.location.href);
            url.searchParams.delete("popup");
            window.history.replaceState({}, "", url.toString());
          }}
          goToLogin={() => {
            setPopUpElement("login");
            const url = new URL(window.location.href);
            url.searchParams.set("popup", "login");
            window.history.replaceState({}, "", url.toString());
          }}
        />
      )}
      {popUpElement === "login" && (
        <Login
          toggle={() => {
            setPopUpElement(undefined);
            const url = new URL(window.location.href);
            url.searchParams.delete("popup");
            window.history.replaceState({}, "", url.toString());
          }}
          goToSignup={() => {
            setPopUpElement("signup");
            const url = new URL(window.location.href);
            url.searchParams.set("popup", "signup");
            window.history.replaceState({}, "", url.toString());
          }}
          goToForgotPassword={() => {
            setPopUpElement("forgotpassword");
            const url = new URL(window.location.href);
            url.searchParams.set("popup", "forgotpassword");
            window.history.replaceState({}, "", url.toString());
          }}
        />
      )}

      {popUpElement === "forgotpassword" && (
        <ForgotPassword
          toggle={() => {
            setPopUpElement(undefined);
            const url = new URL(window.location.href);
            url.searchParams.delete("popup");
            window.history.replaceState({}, "", url.toString());
          }}
          goToSignup={() => {
            setPopUpElement("signup");
            const url = new URL(window.location.href);
            url.searchParams.set("popup", "signup");
            window.history.replaceState({}, "", url.toString());
          }}
        />
      )}

      {popUpElement === "resetpassword" && (
        <ResetPassword
          toggle={() => {
            setPopUpElement(undefined);
            const url = new URL(window.location.href);
            url.searchParams.delete("popup");
            window.history.replaceState({}, "", url.toString());
          }}
          goToLogin={() => {
            setPopUpElement("login");
            const url = new URL(window.location.href);
            url.searchParams.set("popup", "login");
            window.history.replaceState({}, "", url.toString());
          }}
        />
      )}

      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header d-flex align-items-center">
              <Link
                id="mobile_btn"
                to="#"
                onClick={handleClick}
                aria-label="Mobile Menu"
              >
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <a href={"/"} className="navbar-brand logo logo-txt">
                <ImageWithBasePath
                  src="assets/img/logo.svg"
                  className="img-fluid"
                  alt="Logo"
                  width={70}
                />
              </a>
              <a href={"/"} className="navbar-brand logo-small logo-txt">
                <ImageWithBasePath
                  src="assets/img/logo.svg"
                  className="img-fluid"
                  alt="Logo"
                  width={50}
                />
              </a>

              <Dropdown className="navbar-brand logo-small dropdown-wrapper-mobile-custom">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="join-us-btn"
                  aria-label="Join Us"
                >
                  <i className="fas fa-user" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {!localStorage.getItem("token") ? (
                    <>
                      <Dropdown.Item
                        href="/"
                        className="dropdown-item-custom"
                        onClick={(e) => {
                          e.preventDefault();
                          setPopUpElement("signup");
                          const url = new URL(window.location.href);
                          url.searchParams.set("popup", "signup");
                          window.history.replaceState({}, "", url.toString());
                        }}
                      >
                        {t("header.dropDownMenu.unauthenticatedText1")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/"
                        className="dropdown-item-custom"
                        onClick={(e) => {
                          e.preventDefault();
                          setPopUpElement("login");
                          const url = new URL(window.location.href);
                          url.searchParams.set("popup", "login");
                          window.history.replaceState({}, "", url.toString());
                        }}
                      >
                        {t("header.dropDownMenu.unauthenticatedText2")}
                      </Dropdown.Item>
                    </>
                  ) : (
                    <>
                      <div className="listing-description">
                        <div className="row review-sec listing-feature justify-content-between m-0">
                          <div className="review-header">
                            {t("header.dropDownMenu.authenticatedText1")}
                          </div>
                          {benefits.map((item, index) => (
                            <div className="col-md-5 p-0" key={index}>
                              <ul>
                                <li>
                                  <span>
                                    <i className="bx bx-check-double" />
                                  </span>
                                  {item}
                                </li>
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Dropdown.Item
                        href="/"
                        className="dropdown-item-custom"
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.clear();
                          window.location.reload();
                        }}
                      >
                        {t("header.dropDownMenu.authenticatedText2")}
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="navbar-brand logo-small dropdown-wrapper-mobile-custom ms-2">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  variant="outline-dark"
                  aria-label="Language"
                >
                  <img
                    src={`https://flagcdn.com/${i18n.language === "ro" ? "ro" : "gb"}.svg`}
                    width={30}
                    height={20}
                    alt={i18n.language}
                  />

                  <i className="fas fa-chevron-down ms-2" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {lngs.map((lng, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleLng(lng.code)}
                      className="d-flex align-items-center"
                    >
                      <img
                        src={`https://flagcdn.com/${lng.code === "ro" ? "ro" : "gb"}.svg`}
                        width={30}
                        height={20}
                        alt={lng.native}
                        className="me-2"
                      />{" "}
                      {lng.native}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <a href={"/"} className="menu-logo logo-txt">
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="Logo"
                    width={50}
                  />
                </a>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={handleClick}
                  aria-label="Close"
                >
                  {" "}
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li
                  className={
                    location.pathname === "/" || location.pathname === "/home"
                      ? "active"
                      : ""
                  }
                >
                  <a href={"/"}>{t("header.navLinks.home")}</a>
                </li>
                <li
                  className={
                    location.pathname.includes("about-us") ? "active" : ""
                  }
                >
                  <a href={"/despre-noi"}>{t("header.navLinks.aboutUs")}</a>
                </li>
                <li
                  className={
                    location.pathname.includes("tururile-noastre")
                      ? "active"
                      : ""
                  }
                >
                  <a href={"/tururile-noastre"}>
                    {t("header.navLinks.ourTours")}
                  </a>
                </li>
                <li
                  className={
                    location.pathname.includes(
                      "experienta-off-road-voucher-cadou"
                    )
                      ? "active"
                      : ""
                  }
                >
                  <a href={"/experienta-off-road-voucher-cadou"}>
                    {t("header.navLinks.vouchers")}
                  </a>
                </li>
                <li
                  className={
                    location.pathname.includes("partener-de-calatorie")
                      ? "active"
                      : ""
                  }
                >
                  <a href={"/partener-de-calatorie"}>
                    {" "}
                    {t("header.navLinks.travelCompanion")}
                  </a>
                </li>
                <li
                  className={
                    location.pathname.includes(`/training`) ? "active" : ""
                  }
                >
                  <a href={`/training/${process.env.REACT_APP_TRAINING_ID}`}>
                    {t("header.navLinks.training")}
                  </a>
                </li>
                {/* <li
                  className={
                    location.pathname.includes("leave-no-trace") ? "active" : ""
                  }
                >
                  <a href={"/leave-no-trace"}>
                    {" "}
                    {t("header.navLinks.leaveNoTrace")}
                  </a>
                </li> */}
                <li
                  className={location.pathname.includes("faq") ? "active" : ""}
                >
                  <a href={"/faq"}>{t("header.navLinks.faq")}</a>
                </li>

                <li
                  className={
                    location.pathname.includes("contact") ? "active" : ""
                  }
                >
                  <a href={"/contact"}>{t("header.navLinks.contact")}</a>
                </li>

                {!localStorage.getItem("token") && (
                  <li>
                    <a
                      href={"/"}
                      className="join-us-btn"
                      id="id-btn-join-us"
                      onClick={(e) => {
                        e.preventDefault();
                        setPopUpElement("signup");
                        const url = new URL(window.location.href);
                        url.searchParams.set("popup", "signup");
                        window.history.replaceState({}, "", url.toString());
                      }}
                    >
                      {t("header.button.unauthenticatedLabel")}
                    </a>
                  </li>
                )}
                {localStorage.getItem("token") && (
                  <li className="d-flex align-items-center">
                    <BrowserView>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="join-us-btn"
                          aria-label="Join Us"
                        >
                          <i className="fas fa-user" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="/beneficii-membri"
                            className="dropdown-item-custom"
                          >
                            {t("header.dropDownMenu.authenticatedText1")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item-custom"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              localStorage.clear();
                              window.location.reload();
                            }}
                          >
                            {t("header.dropDownMenu.authenticatedText2")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </BrowserView>
                  </li>
                )}
                <li className="d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="outline-dark"
                      aria-label="Language"
                    >
                      <img
                        src={`https://flagcdn.com/${i18n.language === "ro" ? "ro" : "gb"}.svg`}
                        width={30}
                        height={20}
                        alt={i18n.language}
                      />

                      <i className="fas fa-chevron-down ms-2" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {lngs.map((lng, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleLng(lng.code)}
                          className="d-flex align-items-center"
                        >
                          <img
                            src={`https://flagcdn.com/${lng.code === "ro" ? "ro" : "gb"}.svg`}
                            width={30}
                            height={20}
                            alt={lng.native}
                            className="me-2"
                          />{" "}
                          {lng.native}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
